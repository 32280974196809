<template>
  <div class="login-box flex">
    <div :class=" loginError ? 'login-info-error__box': 'login-info__box'">
      <h1 class="info-title">
        欢迎登录心音标注后台管理系统
      </h1>
      <el-form class="info-form" ref="form" :model="loginForm">
        <el-form-item>
          <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入8~20位字母、数字或特殊字符的组合" type="password" v-model="loginForm.password" show-password></el-input>
        </el-form-item>
        <span v-if="passwordErr" style="color: #d52e2e">{{errorPrompt}}</span>
      </el-form>
      <el-button class="login-btn" type="primary" :disabled="disabledLogin"  @click="doLogin">登 录</el-button>
    </div>
  </div>
</template>
<script>
import { login } from '@/serve/module/common'
import md5 from 'js-md5'
export default {
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      passwordErr: false,
      disabledLogin: false,
      errorPrompt: '',

      loginError: false
    }
  },
  methods: {
    doLogin () {
      const params = {
        username: this.loginForm.username,
        password: this.loginForm.password ? md5(this.loginForm.password) : ''
      }
      const pattern = /(?!.*\s)(?!^[0-9]+$)(?!^[^A-Za-z0-9]+$)(?!^[^A-Za-z.*\s]+$)(?!^[^0-9.*\s]+$)^.{8,20}$/
      if (!this.loginForm.username) {
        this.passwordErr = true
        this.errorPrompt = '请输入账号'
        this.loginError = true
      } else if (!this.loginForm.password) {
        this.passwordErr = true
        this.errorPrompt = '请输入密码'
        this.loginError = true
      } else if (!pattern.test(this.loginForm.password)) {
        console.log('密码校验结果：', pattern.test(this.loginForm.password))
        this.passwordErr = true
        this.errorPrompt = '密码有误，请重新输入'
        this.loginError = true
      } else {
        login(params).then(res => {
          if (res && res.code === 200) {
            this.disabledLogin = false
            this.passwordErr = false
            sessionStorage.setItem('Authorization', res.data.token)
            this.loginError = false
            if (res.data.token) {
              this.$router.replace({
                path: '/home'
              })
            }
          } else {
            this.errorPrompt = res.message
            this.passwordErr = true
            this.disabledLogin = false
            this.loginError = true
          }
        }).catch(() => {
          this.$message.error('网络错误')
          this.disabledLogin = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "src/style/common.scss";
  .login-box {
    overflow: hidden;
    @include domSize(100%, 100%);
    align-items: center;
    justify-content: flex-end;
    position: relative;
    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/new.jpg") no-repeat;
    background-size: cover;
    .login-info__box,.login-info-error__box {
      @include domSize(397px, 290px);
      @include bgColor(#fff);
      margin-right: 91px;
      border-radius: 10px;
      padding: 40px 24px 0;
      .info-title {
        @include fs(#000, 24px, 700);
        line-height: 30.32px;
        margin-bottom: 25px;
      }
      .info-subtitle {
        @include fs(#A3b2c2, 14px, 400);
        line-height: 16.32px;
        margin-bottom: 12px;
      }
      .info-form {
        margin-bottom: 24px;
      }
      .login-btn {
        width: 100%;
        height: 40px;
        margin: 0 auto;
        @include fs(#fff, 14px, 700);
      }
    }
    .login-info-error__box {
      height: 330px;
    }
    .el-form-item {
      margin-bottom: 16px !important;
    }
    .form-ver-code-ipt {
      width: 223px;
    }
    .form-ver-code-btn {
      width: 100%;
    }
  }
</style>
